<template>
  <div
    class="search search-group"
    :style="{backgroundColor: (selectedStyle && selectedStyle.color)}"
  >
    <div class="container">
      <div class="search-group__inner">
        <div class="search__content">
          <div class="search__title">Search Groups</div>
          <div class="search__text" v-html="content.block[0]"></div>
          <transition-group name="list" tag="ul" class="search__list">
            <li v-for="category in selectedCategory" :key="category.id + category.name">
              <a href="#" @click.prevent="onSetCategory(category.id)">{{category.name}}</a>
            </li>
          </transition-group>
          <div class="search__category" style="margin-bottom: 15px;">
            <b-dropdown
                :text="selectedStyleName"
                class="m-md-2"
                toggle-class="btn-filter"
              >
              <b-dropdown-item
                v-for="style in styles"
                :key="style.id + style.name"
                :active="selectedStyle === style"
                @click="setSelectedStyle(style)"
              > {{style.name}} </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="search__form">
            <input v-model="search.text" type="text">
            <button class="search__form-btn"><span class="search__form-icon"></span></button>
          </div>
          <div class="search__category">
            <multiselect
              class="select_category"
              v-model="selectedCategory"
              :options="categories"
              :multiple="true"
              :searchable="false"
              :showLabels="true"
              :close-on-select="true"
              :clear-on-select="false"
              :preserve-search="true"
              placeholder="Category"
              label="name"
              track-by="id"
              :preselect-first="false"
            >
              <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single" v-if="values.length && !isOpen">
                  {{ values.length }} selected
                </span>
              </template>
            </multiselect>
            <b-dropdown
              :text="typeLabel"
              class="m-md-2"
              toggle-class="btn-filter"
            >
              <b-dropdown-item @click.prevent="onSetSearchType({ id: 0, text: 'All' })">
                All
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="onSetSearchType({ id: 1, text: 'Open' })">
                Open
              </b-dropdown-item>
              <b-dropdown-item @click.prevent="onSetSearchType({ id: 2, text: 'Private' })">
                Private
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DictionaryService from '@/services/DictionaryService';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Multiselect from 'vue-multiselect';

import exception from '@/mixins/exception';
import content from '@/mixins/content';

export default {
  name: 'SearchGroups',
  components: {
    Multiselect,
    BDropdown,
    BDropdownItem,
  },
  mixins: [exception, content],
  data() {
    return {
      categories: [],
      styles: [],
      search: {
        category_id: [],
        style: 1,
        text: '',
        type: 0,
      },
      typeLabel: 'Status',
      selectedStyleName: 'All Groups',
      selectedCategory: [],
      selectedStyle: null,
    };
  },
  created() {
    this.getCategories();
    this.getStyles();

    if (this.$router.currentRoute.params.searchParams) {
      this.search = this.$router.currentRoute.params.searchParams;

      if (this.$router.currentRoute.params.searchParams.typeLabel) {
        this.typeLabel = this.$router.currentRoute.params.searchParams.typeLabel;
      }

      if (this.$router.currentRoute.params.searchParams.style) {
        this.selectedStyleName = this.$router.currentRoute.params.searchParams.style.name;
        this.selectedStyle = this.$router.currentRoute.params.searchParams.style;
      }

      if (this.$router.currentRoute.params.searchParams.selectedCategory) {
        this.selectedCategory = this.$router.currentRoute.params.searchParams.selectedCategory;
      }
    } else {
      this.$eventBus.$emit('search', this.search);
    }
  },
  computed: {},
  methods: {
    async getCategories() {
      let res = null;

      try {
        res = await DictionaryService.categories();
        this.categories = res.data;
      } catch (e) {
        this.categories = [];
        this.handleException(e);
      }
    },
    async getStyles() {
      let res = null;

      try {
        res = await DictionaryService.styles();
        this.styles = res.data;
      } catch (e) {
        this.styles = [];
        this.handleException(e);
      }
    },
    onSetCategory(categoryID) {
      this.selectedCategory = this.selectedCategory
        .filter((cat) => cat.id !== categoryID);
    },
    onSetSearchType(type) {
      this.typeLabel = type.text;
      this.search = { ...this.search, type: type.id };
    },
    setSelectedStyle(style) {
      this.selectedStyleName = style.name;
      if (this.selectedStyle === style) this.selectedStyle = null;
      else this.selectedStyle = style;
    },
  },
  watch: {
    search(newSearch) {
      this.$eventBus.$emit('search', newSearch);
    },
    'search.text': function searchText(text) {
      this.search = { ...this.search, text };
    },
    selectedCategory(newCategory) {
      this.search = { ...this.search, category_id: newCategory.map((cat) => cat.id) };
    },
    selectedStyle() {
      this.search = { ...this.search, style: this.selectedStyle?.id || 1 };
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
.user-group__ribbon {
  display: inline-block;
  margin: 0;
  margin-right: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  cursor:pointer;
}

.user-group__ribbon.selected {
  background-color: #1d9dd9;
  border-color: #1d9dd9;
}

.user-group__ribbon.selected span {
  color: #fff;
}

.search__list {
  margin-top: 10px;
}

::v-deep .multiselect__option--highlight {
  background: #489dd9;
  outline: none;
  color: #fff;
}
::v-deep span.multiselect__option.multiselect__option--highlight::after {
  background: #489dd9;
}
::v-deep .multiselect__tag {
  background: #489dd9;
}
.page-layout {
  background-image: none !important;
}

.select_category {
  max-width: 140px;
  margin-right: 15px;
}

::v-deep .multiselect__tags {
  border-radius: 10px;
  max-height: 40px;
  padding-top: 7px;
  overflow: hidden;
}

::v-deep .multiselect__select:before {
  top: 75%;
  color: #000;
  border-color: #000 transparent transparent;
  border-width: 11px 6px 0;
}

::v-deep .multiselect__placeholder {
  font-size: 16px;
  padding-top: 0;
  color: #000;
}

::v-deep .multiselect__single {
  margin-top: 3px;
  color: #000;
}

.search__category {
  ::v-deep .btn-filter {
    font-size: 16px;
    font-weight: 400;
    padding: 9.5px 40px 9.5px 15px;
    border-width: 1px;
    background-color: #fff;
  }
}

::v-deep .search__category .btn-filter::after {
    content: '';
    bottom: 11px;
}

.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
</style>
