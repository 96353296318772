<template>
  <div class="user-group__item">
    <div class="user-group__content">
      <div :style="{borderColor: group.style_color}" class="user-group__icon">
        <img alt="" src="@/assets/images/icon-group2.svg">
      </div>
      <div :style="{backgroundColor: group.style_color}" class="user-group__head">
        <div class="user-group__name">
          <h3>{{ group.name }}</h3>
        </div>
        <div class="user-group__ribbon"><span class="crop-text">{{ typeText }}</span></div>
      </div>
      <div class="user-group__body">
        <div class="user-group__body-text">
          <ul class="user-group__body-info">
            <li><strong>{{ group.cnt_user }}</strong> Members</li>
            <li><strong>{{ group.cnt_project }}</strong> Strategy Projects</li>
          </ul>
          {{ group.description }}
        </div>
      </div>
    </div>
    <div class="user-group__more-box">
      <router-link
        :to="{ name: 'profileGroupsView', params: { id: group.slug } }"
        class="user-group__more-btn">
        <i class="icon icon-profile"></i>View group
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListCardGroups',
  components: {},
  mixins: [],
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {
  },
  computed: {
    typeText() {
      return this.group.status === 'request' ? 'the group is awaiting confirmation' : this.group.type;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
</style>
