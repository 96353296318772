<template>
  <div class="search-content">
    <div class="container">
      <div class="search-content__inner">
          <div v-if="text && alert" class="container_alert">
            <Alert :variant="variant" v-if="text">{{ text }}</Alert>
          </div>
          <div v-if="this.$store.getters.getAuthUser" class="user-cabinet__area-top">
            <router-link :to="{ name: 'profileGroupsRequest' }" class="btn btn-primary">
              Request a new group
            </router-link>
          </div>
          <div class="user-group__list user-group__grid">
            <CardGroups v-for="group in groups" :key="group.id" :group="group"/>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardGroups from '@/views/pages/PrivateGroups/Components/CardGroupV2.vue';
import Alert from '@/components/Alert.vue';

import alert from '@/mixins/alert';

export default {
  name: 'ListCardGroups',
  components: {
    CardGroups,
    Alert,
  },
  mixins: [alert],
  props: {
    groups: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  computed: {},
  methods: {},
  mounted() {
    if (this.$route.params.alert) {
      this.alert = this.$route.params.alert;
    }
  },
};
</script>
<style lang="scss" scoped>
@media (min-width: 768px) and (max-width: 992px) {
  .user-group__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
