<template>
  <div>
    <SearchGroups/>
    <ListCardGroups :groups="groups"/>
  </div>
</template>
<script>
import SearchGroups from '@/views/pages/PrivateGroups/Components/SearchGroups.vue';
import ListCardGroups from '@/views/pages/PrivateGroups/Components/ListCardGroups.vue';

import exception from '@/mixins/exception';

import GroupService from '@/services/GroupService';

export default {
  name: 'PrivateGroupsPage',
  mixins: [exception],
  components: {
    SearchGroups,
    ListCardGroups,
  },
  data() {
    return {
      page: 1,
      perPage: 5,
      totalPages: 1,
      groups: [],
      searchData: {},
    };
  },
  created() {
    window.addEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getGroups();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getGroups();
      }
    });

    this.$eventBus.$on('search', this.searchGroups);

    // this.getGroups();
  },
  beforeDestroy() {
    window.removeEventListener('scroll', async () => {
      const {
        scrollTop,
        scrollHeight,
        clientHeight,
      } = document.documentElement;
      const footer = document.getElementsByClassName('footer');
      if (window.innerWidth <= 768
        && scrollTop + clientHeight >= scrollHeight - footer[0].scrollHeight) {
        await this.getGroups();
      }
      if (window.innerWidth > 768 && scrollTop + clientHeight >= scrollHeight) {
        await this.getGroups();
      }
    });
  },
  methods: {
    async getGroups() {
      let res = null;
      if (this.page <= this.totalPages) {
        const query = {
          page: this.page,
          perPage: this.perPage,
        };
        try {
          res = await GroupService.groups({ ...query, ...this.searchData });
          this.groups.push(...res.data);
          this.totalPages = res.headers['x-pagination-page-count'];
          this.page = query.page + 1;
        } catch (e) {
          // this.groups = [];
          this.handleException(e);
        }
      }
    },
    searchGroups(data) {
      this.searchData.style = data.style;

      if (data.type > 0) {
        this.searchData.type = data.type;
      } else {
        delete this.searchData.type;
      }

      if (data.text) {
        this.searchData.text = data.text;
      } else {
        delete this.searchData.text;
      }

      if (data.category_id.length) {
        this.searchData.category_id = data.category_id;
      } else {
        delete this.searchData.category_id;
      }

      // this.searchData.page = '1';
      if (!this.$router.currentRoute.params.searchParams) {
        window.history.pushState(
          {},
          null,
          this.$route.path,
        );
      }

      this.groups = [];
      this.page = 1;
      this.totalPages = 1;
      this.getGroups();
    },
  },
};
</script>
<style lang="scss" scoped>

</style>
